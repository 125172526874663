import { VideoDisplay } from '../views/VideoDisplay';
import { ProfileCard } from './createCard';

export const SearchResults = ({ results }) => {
  // console.log(results);
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
      {results.map((result, i) =>
        result?.video ? (
          <div style={{ width: '30%' }} key={i}>
            <VideoDisplay user={{ videos: [result] }} />
          </div>
        ) : (
          <ProfileCard key={'key' + i} profile={result} i={i} />
        )
      )}
    </div>
  );
};

// Things
// Thing
// part
// piece
