import '../css/Nav.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { logout } from '../services/fetch-utils';

import { useUser } from '../hooks/useUser';
import { useCart } from '../hooks/useCart';

import useRenderCounter from '../services/checkRender';

export default function Nav() {
  // useRenderCounter('NAV');
  const { user } = useUser();
  const { cartCount } = useCart();
  const count = cartCount();
  return (
    <div className="nav-pane">
      {user ? (
        <header className="user-nav">
          <img
            className="avatar"
            src={
              user.avatar_url
                ? user.avatar_url
                : 'https://nqbvdgzoxvmdlnjovyqu.supabase.in/storage/v1/object/public/avatars/default-avatar/userexpression.png'
            }
            alt="PROFILE PIC"
          ></img>
          <h1 className="user-nav-greetings">{`Welcome ${user.username}`}</h1>
          <span style={{ display: 'flex', flexDirection: 'column' }}>
            <span>
              Wallet Balance: ${user?.wallet?.balance === undefined ? '0' : user?.wallet?.balance}
            </span>
            <Link to={`/cart`} className="qr-link">
              <span className="cart-logo-and-count-container">
                <img
                  // onClick={() => navigate('/cart')}
                  className="checkout-cart-logo"
                  src="/cart-icon.png"
                  style={{ height: '35px' }}
                ></img>
                {count > 0 && <span className="cart-length-indicator">{count}</span>}
              </span>
            </Link>
            <Link to={`/live-space/${user.user_id}`} className="qr-link">
              Start Live
            </Link>
          </span>
        </header>
      ) : null}
      <h2 style={{ margin: 0 }}>Express Yourself and Connect With Others</h2>
      <marquee direction="left">
        <p className="marquee-scroller">Do you have what it takes to be the best?</p>
      </marquee>
      <nav>
        <Link className="nav-link" to="/">
          Home
        </Link>{' '}
        |{' '}
        <Link className="nav-link" to="/profile">
          Profile
        </Link>{' '}
        |{' '}
        <Link className="nav-link" to="/battles">
          Battle Field
        </Link>{' '}
        |{' '}
        <Link className="nav-link" to="/about">
          About
        </Link>{' '}
        |{' '}
        {user ? (
          <button className="logout nav-link" onClick={logout}>
            Logout
          </button>
        ) : (
          <Link className="nav-link" to="/auth">
            Sign In/Sign Up
          </Link>
        )}
      </nav>
    </div>
  );
}
