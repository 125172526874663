import '../css/Cart.css';
import {
  fetchArtistWalletBalance,
  donateToArtist,
  updateWalletAfterDonate,
} from '../services/fetch-utils';
import { toast } from 'react-hot-toast';
import { useCart } from '../hooks/useCart';
import { useUser } from '../hooks/useUser';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLedger } from '../hooks/useLedger';
import { ProductView } from '../views/ProductView';
import { CartBillDisplay } from '../components/CartBillDisplay';

// import { Checkout } from '../components/Checkout';

export const Cart = () => {
  const { user, updateWallet } = useUser();
  const { createNewTransaction } = useLedger();
  const { cart, removeFromCart, clearCart } = useCart();
  const [totalPrice, setTotalPrice] = useState(0);
  const [stripeServiceFee, setStripeServiceFee] = useState(0);
  const [artistWalletAmount, setArtistWalletAmount] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    fetchArtistWalletBalance(cart[0]?.user_id).then((res) => setArtistWalletAmount(res));
  }, []);

  useEffect(() => {
    setTotalPrice(cart.reduce((acc, curr) => acc + Number(curr.price * curr.quantity), 0));
  }, [cart]);

  useEffect(() => {
    setStripeServiceFee((totalPrice + 0.3) / (1 - 0.029) - totalPrice);
  }, [totalPrice]);

  const handleRemoveCartItem = (i) => {
    removeFromCart(i);
  };

  const handleCheckout = () => {
    // const response = Checkout(cart);
    if (user.wallet?.balance >= totalPrice) {
      const updatedArtistBalance = Number(totalPrice) + Number(artistWalletAmount.balance);
      const updateUserWallet = Number(user.wallet?.balance) - Number(totalPrice);
      try {
        const artistDonation = donateToArtist(updatedArtistBalance, cart[0].user_id)
          .catch((error) => error.message)
          .then(() => updateWalletAfterDonate(updateUserWallet, user?.user_id))
          .then((res) => {
            updateWallet(res?.balance);
          })
          .then(() => {
            createNewTransaction(user?.user_id, cart[0].user_id, totalPrice);
          })
          .then((res) => console.log('RESPONSE IN CART FROM CREATE NEW TRANX', res));

        toast.promise(artistDonation, {
          loading: 'Just a moment while we process your donation',
          success: `Donation successfully sent to ${cart[0].username}`,
          error: 'Oops! Something went wrong. Please try again.',
        });
      } catch (error) {
        toast.error('Something went wrong. Please try again');
      }
    } else toast.error('Not Enough Funds');
  };

  return (
    <div className="cart-container">
      <span className="cart-items-container">
        {cart.length > 0 &&
          cart.map((item, i) => {
            return (
              <span
                key={item.id + '-' + item.type + '-' + item.name + '-' + i}
                className="cart-item-view"
              >
                <ProductView product={item} />
                <button onClick={() => handleRemoveCartItem(i)}>Remove From Cart</button>
              </span>
            );
          })}
      </span>

      <aside className="cart-bill-display">
        <CartBillDisplay />

        <span>
          Stripe Service Fee: ${totalPrice > 0 ? `${stripeServiceFee.toFixed(2)}` : '0.00'}
        </span>
        <span>Subtotal: ${totalPrice > 0 ? `${totalPrice.toFixed(2)}` : '0.00'}</span>
        <span>
          Total: $
          {totalPrice > 0
            ? `${(Number(totalPrice.toFixed(2)) + Number(stripeServiceFee.toFixed(2))).toFixed(2)}`
            : '0.00'}
        </span>
        <button onClick={() => clearCart()}>Clear Cart</button>
        <button onClick={() => handleCheckout()}>Finalize Transaction</button>
        <h4>Figure Out How To Notify Artist Of Sale.</h4>
        <h5>Clear Cart After Transaction</h5>
      </aside>
    </div>
  );
};
