import './App.css';
import Nav from './routes/Nav';
import { Outlet } from 'react-router-dom';
import { Sidebar } from './components/Sidebar';
import { useUser } from './hooks/useUser';
import { Suspense } from 'react';

function App() {
  const { user } = useUser();
  return (
    <div className="App">
      <div className="navigation">
        <Nav />
      </div>
      {user?.username ? <Sidebar /> : null}
      {/* I KINDA WANNA PUT A TIMER ON THIS FALLBACK AND HAVE AN INTENTIONAL PAUSE */}
      <Suspense fallback={<h1>DATA INCOMING</h1>}>
        {/* MATRIX LOADING SCREEN? CLASSIC WHEEL? ANYTHING ELSE?.. */}
        {/* Dancing musical notation.. ??  */}
        <Outlet />
      </Suspense>
    </div>
  );
}

export default App;
