import '../css/Truth.css';
import React, { lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useUser } from '../hooks/useUser';

import App from '../App';
import Auth from './Auth';
import Live from './Live';
import Home from './Home';

const Profile = lazy(() => import('./Profile'));
const BattleField = lazy(() => import('./BattleField'));
const PublicProfile = lazy(() => import('./PublicProfile'));
const Events = lazy(() => import('./Events'));

import { Toaster } from 'react-hot-toast';
import useRenderCounter from '../services/checkRender';
import { VideoMedia } from '../components/VideoMedia';
import { About } from './About';
import { Support } from '../components/Support';
import { Detail } from './Detail';
import { Cart } from './Cart';

export default function Truth() {
  // useRenderCounter('TRUTH');

  const { user } = useUser();
  return (
    <div className="main-div">
      <BrowserRouter>
        <Toaster />
        <Routes>
          <Route element={<App />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/auth" element={<Auth />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/cart" element={<Cart />} />
            <Route exact path="/donate/:username" element={<Support />} />
            <Route exact path="/profile" element={user ? <Profile /> : <Auth />} />
            <Route exact path="/battles" element={user ? <BattleField /> : <Auth />} />
            <Route exact path={`/detail/:id`} element={<Detail />} />
            <Route exact path={`/profile/:id`} element={<PublicProfile />} />
            <Route exact path={`/live-space/:id`} element={user ? <Live /> : <Auth />} />
            <Route exact path={`/media/:id`} element={<VideoMedia />} />
            <Route exact path={`/event/:id/:attending`} element={<Events />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
