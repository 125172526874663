import './index.css';
import React from 'react';
// import { render } from 'react-dom';
import Truth from './routes/Truth';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './context/UserProvider';
import { BattleProvider } from './context/BattleProvider';
import { LedgerProvider } from './context/LedgerProvider';
import { ChatMessageProvider } from './context/ChatMessageProvider';
import { CartProvider } from './context/CartProvider';

const rootElement = document.getElementById('root');

const renderApp = () => {
  const root = createRoot(rootElement);
  return root.render(
    <React.StrictMode>
      <UserProvider>
        <BattleProvider>
          <ChatMessageProvider>
            <LedgerProvider>
              <CartProvider>
                <Truth />
              </CartProvider>
            </LedgerProvider>
          </ChatMessageProvider>
        </BattleProvider>
      </UserProvider>
    </React.StrictMode>
  );
};
renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
