import { useState, useEffect } from 'react';
import { fetchLatestSongs } from '../services/fetch-utils';
import { SongDisplay } from '../views/SongDisplay';

export default function LatestSongs() {
  const [songs, setSongs] = useState([]);

  useEffect(() => {
    try {
      fetchLatestSongs().then((songs) => setSongs(songs.reverse()));
    } catch (error) {
      console.error('No Media yet');
      setSongs([]);
    }
  }, []);

  return (
    <div className="all-songs">
      {songs.map((song, i) => {
        return (
          <div key={song.username + i} className="home-song-display">
            <SongDisplay song={song} />
          </div>
        );
      })}
    </div>
  );
}
