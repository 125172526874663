import sha256 from 'crypto-js/sha256';

const EC = require('elliptic').ec;
const ec = new EC('secp256k1');

export const tranxCrypto = ({ sender_id, receiver_id, amount }) => {
  const calculateHash = () => {
    const hash = sha256(sender_id + receiver_id + amount + Date.now()).toString();
    return [hash];
  };

  const signTranx = () => {
    const tranxHash = calculateHash();
    const key = ec.genKeyPair();
    const signature = key.sign(tranxHash);
    // const derSign = signature.toDER();
    // console.log('CRYPTO FUNCTION KEY VERIFY', key.verify(tranxHash, derSign), signature);
    // console.log('WHAT IS A DER???????', derSign);
    // Its a binary representation of the signature
    return signature;
  };
  return signTranx();
};
