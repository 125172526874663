import { createContext, useState, useEffect } from 'react';
import { fetchAllBattles } from '../services/fetch-utils';

export const BattleContext = createContext(null);

export const BattleProvider = ({ children }) => {
  const [battles, setBattles] = useState([]);

  useEffect(() => {
    try {
      fetchAllBattles().then((battles) => setBattles(battles.reverse()));
    } catch (error) {
      console.error('No Battles yet');
      setBattles([]);
    }
  }, []);
  return (
    <BattleContext.Provider value={{ battles, setBattles }}>{children}</BattleContext.Provider>
  );
};
