import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import useRenderCounter from '../services/checkRender';
import { editBattleComment, fetchBattleComments } from '../services/fetch-utils';

export default function EditBattleComment({ comment, setComments, toastId }) {
  useRenderCounter('EDITCOMMENT');
  const [newComment, setNewComment] = useState('');

  const commentSpan = document.getElementById(`comment-edit-span-${comment.battle}`);

  useEffect(() => {
    commentSpan?.addEventListener('input', (e) => handleChange(e), false);
  }, [commentSpan]);

  const handleChange = (event) => {
    event.preventDefault();
    setNewComment(event.target.childNodes[0]?.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const commentText = newComment ? newComment : e.target.childNodes[0].innerText;

    const response = editBattleComment(comment.id, commentText)
      .catch((error) => error.message)
      .then(() => fetchBattleComments(comment.battle))
      .then((res) => {
        // THIS SETTER IS FROM PROPS. IT SETS STATE IN PARENT COMPONENT
        setComments(res.data);
        // THIS IS THE SETTER FOR NEWCOMMENT IN STATE OF THIS SCOPE
        setNewComment('');
        toast.dismiss(toastId);
      });

    toast.promise(response, {
      loading: 'Just a moment while we edit your Comment',
      success: 'Comment successfully edited',
      error: 'Oops! Something went wrong. Please try again.',
    });
  };

  return (
    <form onSubmit={handleSubmit} style={{ paddingBottom: '.5em' }}>
      <label>
        <span
          id={`comment-edit-span-${comment.battle}`}
          className="textarea"
          role="textbox"
          contentEditable
        >
          {comment.comments}
        </span>
      </label>
      <button style={{ cursor: 'pointer' }}>Edit comment</button>
      <span
        style={{
          border: '1px solid black',
          borderRadius: '2px',
          margin: '5px 2px',
          padding: '1px 3px',
          backgroundColor: '#f0f0f0',
          fontSize: '13.33333px',
          fontWeight: '500',
          cursor: 'pointer',
        }}
        role={'button'}
        onClick={() => toast.dismiss(toastId)}
      >
        Cancel Edit
      </span>
    </form>
  );
}
