import React, { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useUser } from '../hooks/useUser';
import useRenderCounter from '../services/checkRender';
import { submitPublicComment, fetchPublicComments } from '../services/fetch-utils';

export default function UploadPublicComment({ video_id, setComments }) {
  useRenderCounter('UPLOAD PUBLIC COMMENT');
  const { user } = useUser();
  const [comment, setComment] = useState('');

  const publicCommentSpan = document.getElementById(`public-comment-span-${video_id}`);

  useEffect(() => {
    publicCommentSpan?.addEventListener('input', (event) => handleChange(event), false);
  }, [publicCommentSpan]);

  const handleChange = () => {
    setComment(publicCommentSpan?.innerText);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = submitPublicComment(video_id, comment, user.username, user.id)
      .catch((error) => error.message)
      .then(() => fetchPublicComments(video_id))
      .then((res) => {
        // THIS SETTER IS FROM PROPS. IT SETS STATE IN PARENT COMPONENT
        setComments(res.data.reverse());
        // THIS IS THE SETTER FOR COMMENT IN STATE OF THIS SCOPE
        setComment('');
        publicCommentSpan.innerText = '';
      });

    toast.promise(response, {
      loading: 'Just a moment while we upload your Comment',
      success: 'Comment successfully uploaded',
      error: 'Oops! Something went wrong. Please try again.',
    });
  };

  return (
    <form onSubmit={handleSubmit} style={{ paddingBottom: '.5em' }}>
      <label>
        {/* Submit comment <textarea type="text" value={comment} onChange={handleChange} /> */}
        <span
          id={`public-comment-span-${video_id}`}
          className="textarea public-text"
          role="textbox"
          value={comment}
          contentEditable
        ></span>
      </label>
      <button>Submit comment</button>
    </form>
  );
}
