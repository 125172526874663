import { useContext } from 'react';
import { ChatMessageContext } from '../context/ChatMessageProvider';

export const useChats = () => {
  const context = useContext(ChatMessageContext);
  if (context === null) {
    throw new Error('Chat Message context must come from ChatMessageProvider');
  }
  return context;
};
