import '../css/About.css';

export const About = () => {
  return (
    <div className="about-container">
      <h1>Text Description?</h1>
      <h2>Vide Intro?</h2>
      <h3>Donation Link</h3>
    </div>
  );
};
