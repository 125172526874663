import React from 'react';
import { Chats } from '../components/Chats';
import LatestSongs from '../components/LatestSongs';
import LatestVideos from '../components/LatestVideos';
import { Tabs } from '../components/Tabs';
import Search from '../features/Search';
import useRenderCounter from '../services/checkRender';

export default function Home() {
  useRenderCounter('HOME');
  const home = {
    tabs: ['Latest Videos', 'Latest Songs', 'Messages', 'Search'],
    content: [
      <LatestVideos key={'latest-vids'} />,
      <LatestSongs key={'latest-songs'} />,
      <Chats key={'chats'} />,
      <Search key={'search'} />,
    ],
  };

  return <Tabs page={home} />;
}
