import '../css/MediaPlayer.css';

export const MediaPlayer = () => {
  return (
    <div className="media-player-shell">
      <span className="playlist-items-display">
        <ul className="playlist-display"></ul>
      </span>
      <span className="media-display-span"></span>
      <span className="media-details"></span>
    </div>
  );
};
