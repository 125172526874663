import toast from 'react-hot-toast';
import React, { useState } from 'react';
import { useUser } from '../hooks/useUser';
import useRenderCounter from '../services/checkRender';
import { updateUsername } from '../services/fetch-utils';

export default function UpdateUsername() {
  // useRenderCounter('CHANGE USERNAME');
  const { user, setUser } = useUser();

  const [username, setUsername] = useState('');

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    updateUsername(user.user_id, username)
      .then((updatedUser) => {
        updatedUser?.error?.code === '23505' && toast.error('Username Already Exists. Try Again');

        if (updatedUser?.data) {
          toast.success('Username Successfully Updated');
          setUser(updatedUser.data[0]);
        }
      })
      .then(() => {
        e.target[0].value = null;
        setUsername(null);
      });
  };
  return (
    <form onSubmit={handleSubmit}>
      <label>
        Change Username
        <input type="text" onChange={handleUsernameChange}></input>
      </label>
      <button>Submit</button>
    </form>
  );
}
