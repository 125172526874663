/* eslint-disable indent */

import '../css/SingleMessage.css';
import { useUser } from '../hooks/useUser';
import { useNavigate } from 'react-router-dom';
import { dateConverter } from '../services/formatDate';
import { deleteChatMessage } from '../services/fetch-utils';

export const ProfileCard = ({ profile, i }) => {
  const navigate = useNavigate();
  return (
    <div key={profile.username + i} style={{ padding: '1%' }}>
      <img
        src={
          profile.avatar_url
            ? profile.avatar_url
            : 'https://nqbvdgzoxvmdlnjovyqu.supabase.in/storage/v1/object/public/avatars/default-avatar/userexpression.png'
        }
        style={{ width: '100px', height: '100px', borderRadius: '50%' }}
        onClick={() => navigate(`../profile/${profile.id}`)}
      />
      {profile?.media ? (
        <>
          <audio controls>
            <source src={profile.media} type="audio/mp3"></source>
          </audio>
          <p>Title: {profile.title}</p>
          <p>Description: {profile.description}</p>
        </>
      ) : null}
      <p onClick={() => navigate(`../profile/${profile.id}`)}>{profile.username}</p>
    </div>
  );
};

export const VideoDetailCard = () => {};
export const VideoListCard = () => {};

export const VideoInfoCard = ({ videoInfo }) => {
  import('../css/VideoInfoCard.css');

  // THINGS THING PART PIECE
  // THINGS THING PART PIECE
  // THINGS THING PART PIECE
  // THINGS THING PART PIECE
  // THINGS THING PART PIECE
  // THINGS THING PART PIECE
  const navigate = useNavigate();

  return (
    <section onClick={() => navigate(`../profile/${videoInfo.id}`)} className="video-details">
      <img
        className="user-video-avatar"
        src={
          videoInfo?.profile?.avatar_url
            ? videoInfo?.profile?.avatar_url
            : 'https://nqbvdgzoxvmdlnjovyqu.supabase.in/storage/v1/object/public/avatars/default-avatar/userexpression.png'
        }
      ></img>
      <p className="user-video-username">{videoInfo?.username}</p>
      <p className="video-description-title">Title: {videoInfo?.title}</p>
      <p className="video-description-details-paragraph">Description: {videoInfo?.description}</p>
      <p className="video-description-uploaded">Uploaded: {dateConverter(videoInfo?.created_at)}</p>
      <p className="view-count">Views: {videoInfo?.views}</p>
    </section>
  );
  // THINGS THING PART PIECE
  // THINGS THING PART PIECE
  // THINGS THING PART PIECE
  // THINGS THING PART PIECE
  // THINGS THING PART PIECE
  // THINGS THING PART PIECE
};

export const SingleMessage = ({ message }) => {
  const { user } = useUser();
  return (
    <span
      className="single-message-span"
      style={{
        justifyContent: message.sender === user?.username ? 'right' : 'left',
      }}
    >
      <p
        className="single-message-p"
        style={{ backgroundColor: message.sender === user.username ? 'blue' : 'grey' }}
      >
        {message.message}
      </p>
    </span>
  );
};
