/* eslint-disable indent */
import { useUser } from '../hooks/useUser';
import { fetchChats, fetchChatMessages, deleteChatMessage } from '../services/fetch-utils';
import { createContext, useState, useEffect } from 'react';
import { chatSubscription } from '../services/fetch-utils';

export const ChatMessageContext = createContext(null);

export const ChatMessageProvider = ({ children }) => {
  const { user } = useUser();

  const [openChats, setOpenChats] = useState([]);
  const [newMessages, setNewMessages] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);

  useEffect(() => {
    if (user?.username) {
      fetchChats(user?.username).then((res) => setOpenChats(res.data));
      chatSubscription(chatUpdater);
    }
  }, [user]);

  // useEffect(() => {
  //   console.log('LIST OF OPEN CHATS', openChats);
  // }, [openChats]);

  useEffect(() => {
    if (chatMessages?.messages) {
      const existingMessages = chatMessages?.messages;
      const updatedMessageArray = [...existingMessages, newMessages];
      const newChatObj = {
        chat_id: chatMessages.chat_id,
        created_at: chatMessages.created_at,
        user_1: chatMessages.user_1,
        user_2: chatMessages.user_2,
        messages: [...updatedMessageArray],
      };
      setChatMessages(newChatObj);
    }
  }, [newMessages]);

  const chatUpdater = (payload) => {
    console.log('!!! PAYLOAD !!!', payload);
    switch (payload.eventType) {
      case 'INSERT':
        console.log('INSERT TYPE NEW ROW', payload.new);
        setNewMessages(payload.new);
        break;
      case 'DELETE':
        fetchChatMessages(payload.old.chat_id).then((updatedMessageArray) =>
          setChatMessages({
            chat_id: chatMessages.chat_id,
            created_at: chatMessages.created_at,
            user_1: chatMessages.user_1,
            user_2: chatMessages.user_2,
            messages: [...updatedMessageArray.data],
          })
        );
        break;

      default:
        break;
    }
  };

  return (
    <ChatMessageContext.Provider
      value={{
        openChats,
        setOpenChats,
        chatMessages,
        setChatMessages,
        chatUpdater,
        newMessages,
        setNewMessages,
      }}
    >
      {children}
    </ChatMessageContext.Provider>
  );
};
