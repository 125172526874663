import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import useRenderCounter from '../services/checkRender';
import { editPublicComment, fetchPublicComments } from '../services/fetch-utils';

export default function EditPublicComment({ comment, setComments, toastId }) {
  useRenderCounter('EDITCOMMENT');
  const [newComment, setNewComment] = useState('');

  const commentSpan = document.getElementById(`comment-span-${comment.battle}`);

  useEffect(() => {
    commentSpan?.addEventListener('input', (event) => handleChange(event), false);
  }, [commentSpan]);

  const handleChange = () => {
    console.log('EVENT IN PLACE WHERE EVENT IS', commentSpan);
    setNewComment(commentSpan?.innerText);
  };

  const handleSubmit = async (e) => {
    const commentText = newComment ? newComment : e.target.childNodes[0].innerText;
    e.preventDefault();
    const response = editPublicComment(comment.id, commentText)
      .catch((error) => error.message)
      .then(() => fetchPublicComments(comment.video_id))
      .then((res) => {
        // THIS SETTER IS FROM PROPS. IT SETS STATE IN PARENT COMPONENT
        setComments(res.data.reverse());
        // THIS IS THE SETTER FOR NEWCOMMENT IN STATE OF THIS SCOPE
        setNewComment('');
        toast.dismiss(toastId);
      });

    toast.promise(
      response,
      {
        loading: 'Just a moment while we edit your Comment',
        success: 'Comment successfully edited',
        error: 'Oops! Something went wrong. Please try again.',
      },
      {
        duration: 1000,
      }
    );
  };

  return (
    <form onSubmit={handleSubmit} style={{ paddingBottom: '.5em' }}>
      <label>
        <span
          id={`comment-span-${comment.battle}`}
          className="textarea"
          role="textbox"
          contentEditable
        >
          {comment.comments}
        </span>
      </label>
      <button style={{ cursor: 'pointer' }}>Edit comment</button>
      <span
        style={{
          border: '1px solid black',
          borderRadius: '2px',
          margin: '5px 2px',
          padding: '1px 3px',
          backgroundColor: '#f0f0f0',
          fontSize: '13.33333px',
          fontWeight: '500',
          cursor: 'pointer',
        }}
        role={'button'}
        onClick={() => toast.dismiss(toastId)}
      >
        Cancel Edit
      </span>
    </form>
  );
}
