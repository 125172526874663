import { useState } from 'react';
import { signUpUser, createProfile, createWallet } from '../services/fetch-utils';

export const SignUpForm = () => {
  const [usernameSignUp, setUsernameSignUp] = useState('');
  const [emailSignUp, setEmailSignUp] = useState('');
  const [passwordSignUp, setPasswordSignUp] = useState('');

  const handleSignUpUsernameChange = (e) => {
    setUsernameSignUp(e.target.value);
  };
  const handleSignUPEmailChange = (e) => {
    setEmailSignUp(e.target.value);
  };
  const handleSignUpPasswordChange = (e) => {
    setPasswordSignUp(e.target.value);
  };
  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    const user = await signUpUser(emailSignUp, passwordSignUp);
    await createProfile(usernameSignUp, emailSignUp);
    const newWallet = await createWallet(user?.id);
    if (user) {
      console.log('WALLET OBJECT FROM SIGN UP', newWallet);
      // location.replace('/profile');
    }
  };
  return (
    <div className="auth-form-container">
      <p className="auth-form-title">Sign Up</p>
      <form className="auth-form" onSubmit={handleSignUpSubmit}>
        <label className="auth-form-input-label">
          Username
          <input
            className="auth-form-input"
            value={usernameSignUp}
            onChange={handleSignUpUsernameChange}
          ></input>
        </label>
        <label className="auth-form-input-label">
          Email
          <input
            className="auth-form-input"
            value={emailSignUp}
            onChange={handleSignUPEmailChange}
            type={'email'}
          ></input>
        </label>
        <label className="auth-form-input-label">
          Password
          <input
            className="auth-form-input"
            value={passwordSignUp}
            onChange={handleSignUpPasswordChange}
            type={'password'}
          ></input>
        </label>

        <button className="auth-form-submit-button">Submit</button>
      </form>
    </div>
  );
};
