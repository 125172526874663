/* eslint-disable indent */
import EditBattleComment from '../forms/EditBattleComment';
import EditPublicComment from '../forms/EditPublicComment';
import React from 'react';
import { useUser } from '../hooks/useUser';
import useRenderCounter from '../services/checkRender';
import { dateConverter } from '../services/formatDate';
import { useNavigate } from 'react-router-dom';
import {
  deleteBattleComments,
  fetchBattleComments,
  deletePublicComments,
  fetchPublicComments,
} from '../services/fetch-utils';
import { toast } from 'react-hot-toast';

export default function Comment({ comment, setComments, location }) {
  const { user } = useUser();
  const navigate = useNavigate();

  useRenderCounter('COMMENT', comment);

  const handleCommentDelete = () => {
    switch (location) {
      case 'battle':
        try {
          const deleteBattleResponse = deleteBattleComments(comment.id)
            .catch((error) => error.message)
            .then(() => fetchBattleComments(comment.battle))
            .then((res) => {
              // THIS SETTER IS FROM PROPS. IT SETS STATE IN PARENT COMPONENT
              setComments(res.data);
            });

          toast.promise(deleteBattleResponse, {
            loading: 'Just a moment while we delete your Comment',
            success: 'Comment successfully deleted',
            error: 'Oops! Something went wrong. Please try again.',
          });
        } catch (error) {
          toast.error('Something went wrong. Please try again');
        }
        break;
      case 'public':
        try {
          const deletePublicResponse = deletePublicComments(comment.id)
            .catch((error) => error.message)
            .then(() => fetchPublicComments(comment.video_id))
            .then((res) => {
              console.log('RESPONSE IN PUBLIC VIDEO COMMENT SECTION', res);
              // THIS SETTER IS FROM PROPS. IT SETS STATE IN PARENT COMPONENT
              setComments(res.data.reverse());
            });

          toast.promise(deletePublicResponse, {
            loading: 'Just a moment while we delete your Comment',
            success: 'Comment successfully deleted',
            error: 'Oops! Something went wrong. Please try again.',
          });
        } catch (error) {
          toast.error('Something went wrong. Please try again');
        }
        break;

      default:
        toast.error('Location does not exist. Please try again from this universe');
    }
  };

  const handleCommentEdit = () => {
    location === 'battle'
      ? toast(
          (t) => <EditBattleComment comment={comment} setComments={setComments} toastId={t.id} />,
          {
            duration: 3600000,
          }
        )
      : toast(
          (t) => <EditPublicComment comment={comment} setComments={setComments} toastId={t.id} />,
          {
            duration: 3600000,
          }
        );
  };

  let toFrom = 'start';
  let edit = false;
  if (comment.username === user?.username) {
    toFrom = 'end';
    edit = true;
  }
  const timeStamp = dateConverter(comment.created_at);

  return (
    <div className="testing-style" style={{ display: 'flex', justifyContent: toFrom }}>
      <span className="comment-string" style={{ maxWidth: '50%', margin: 0 }}>
        <p
          className="comment-username"
          onClick={() => navigate(`../profile/${comment.user_id}`)}
        >{`${comment.username} `}</p>
        {` : ${comment.comments} sent ${timeStamp} `}
      </span>
      {edit ? (
        <span className="comment-controls">
          <img
            className="comment-control-icons"
            src="/edit-icon.png"
            style={{ width: '15px', marginLeft: '3px' }}
            onClick={() => handleCommentEdit()}
          ></img>
          <img
            className="comment-control-icons"
            src="/delete-icon.png"
            style={{ width: '15px', marginLeft: '3px' }}
            onClick={() => handleCommentDelete()}
          ></img>
        </span>
      ) : null}
    </div>
  );
}
