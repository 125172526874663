import { useLocation } from 'react-router-dom';
import '../css/Support.css';
import { SupportArtistForm } from '../forms/SupportArtistForm';

export const Support = () => {
  const location = useLocation();
  return (
    <div className="support-container">
      <h1>Show Your Support for {location.state.username}</h1>
      <SupportArtistForm />
    </div>
  );
};
