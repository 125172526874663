import '../css/Live.css';
import { useEffect, useState } from 'react';
import { videoFeed } from '../services/AudioVisual';
import useRenderCounter from '../services/checkRender';
import { MediaPlayer } from '../components/MediaPlayer';
// import { SkeletonVideo } from '../features/SkeletonVideo';

export default function Live() {
  useRenderCounter('LIVE');
  // const [date, setDate] = useState(new Date());
  const [camOn, setcamOn] = useState(false);

  let startStop;
  {
    camOn ? (startStop = 'Stop') : (startStop = 'Start');
  }

  if (!camOn && document.getElementById('videoStream')) {
    document.getElementById('videoStream').remove();
  }

  useEffect(() => {
    const resolveVideo = async () => {
      camOn && (await videoFeed());
    };
    resolveVideo();
  }, [camOn]);

  return (
    <div className="live-container">
      <h1>Test area for stuff</h1>
      <h3>Gonna eventually make this the live battle</h3>

      {/* <Calendar onChange={(e) => handleChange(e)} value={date} /> */}
      {/* <SkeletonVideo /> */}
      {/* {check ? check : null} */}
      <button onClick={() => setcamOn(camOn ? false : true)}>{`${startStop}`} Video</button>
      <div className="Live" id="Live" style={{ height: '100vh' }}></div>
      <MediaPlayer />
    </div>
  );
}
