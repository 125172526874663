import { useContext } from 'react';
import { LedgerContext } from '../context/LedgerProvider';

export const useLedger = () => {
  const context = useContext(LedgerContext);
  if (context === null) {
    throw new Error('Ledger context must come from LedgerProvider');
  }
  return context;
};
