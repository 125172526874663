import { client, checkError } from './client';
import { parseImagePath } from './parseImagePath';
import { stringParser } from './stringParser';

export const authListener = () => {
  client.auth.onAuthStateChange((event, session) => {
    if (event === 'SIGNED_OUT' || event === 'USER_DELETED') {
      // delete cookies on sign out
      const expires = new Date(0).toUTCString();
      document.cookie = `my-access-token=; path=/; expires=${expires}; SameSite=Lax; secure`;
      document.cookie = `my-refresh-token=; path=/; expires=${expires}; SameSite=Lax; secure`;

      // REMOVE LOG AFTER DEV
      console.log('!!!!!COOKIES REMOVED FROM USER AUTH!!!!!');
      // REMOVE LOG AFTER DEV
    } else if (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED') {
      const maxAge = 24 * 60 * 60; // 1 Day
      document.cookie = `my-access-token=${session.access_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`;
      document.cookie = `my-refresh-token=${session.refresh_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`;
    }
  });
};

export const fetchUser = () => {
  // Check for cookie set in UserProvider
  const cookieAuth = document?.cookie || null;
  const cookies = cookieAuth ? stringParser(document?.cookie) : {};
  const refreshToken = cookies['my-refresh-token'];
  const accessToken = cookies['my-access-token'];

  // If user has valid cookies, update user session
  if (refreshToken && accessToken) {
    client.auth.setSession({
      refresh_token: refreshToken,
      access_token: accessToken,
    });
  } else {
    // make sure you handle this case!
    console.log('YOU ARE NOT LOGGED IN');
    throw new Error('User is not authenticated.');
  }

  // returns user information
  return client.auth.user();
};

export const fetchUserProfile = async (user_id) => {
  const profile = await client
    .from('profiles')
    .select(
      '*, videos:video_uploads(*), songs:songs(*), merch:merchandise(*), wallet(balance), attending_event(events(*))'
    )
    .match({ user_id })
    .single();
  console.log('USER DATA ON SIGNIN', profile.data);
  return profile.data;
};

export const fetchProfileById = async (id) => {
  const profile = await client
    .from('profiles')
    .select(
      '*, videos:video_uploads(*), songs:songs(*), merch:merchandise(*), attending_event(events(*))'
    )
    .match({ id })
    .single();
  console.log('PROFILE IN FETCH CALL!!!', profile);
  return checkError(profile);
};

export const fetchProfileByUserId = async (user_id) => {
  const profile = await client.from('profiles').select().match({ user_id }).single();

  return checkError(profile);
};

// Use or lose checkAuth...
export const checkAuth = () => {
  const user = fetchUser();

  if (!user) {
    location.replace('./auth');
  }
};

// Use or lose redirectIf...
export const redirectIfLoggedIn = () => {
  if (fetchUser()) {
    location.replace('./profile');
  }
};

export const signUpUser = async (email, password) => {
  const response = await client.auth.signUp({ email, password });
  return response.user;
};

export const signInUser = async (email, password) => {
  const response = await client.auth.signIn({ email, password });
  return response.user;
};

export const logout = async () => {
  await client.auth.signOut();
  return location.replace('../auth');
};

export const createProfile = async (username, email) => {
  const response = await client.from('profiles').insert([
    {
      username,
      email,
    },
  ]);
  return checkError(response);
};

export const createWallet = async (user_id) => {
  const response = await client.from('wallet').insert([
    {
      user_id,
    },
  ]);
  return checkError(response);
};

// UPDATE USERNAME DOES NOT UPDATE ACROSS WHOLE SITE. FUNCTION NEEDS TO BE UPDATED
export const updateUsername = async (user_id, username) => {
  const response = await client.from('profiles').update({ username }).eq('user_id', user_id);
  console.log('RESPONSE FROM USERNAME', response);

  return response;
};

export const uploadVideo = async ({ newVideo }) => {
  await client.from('videos').insert([
    {
      tags: [...newVideo.tags],
      name: newVideo.name ? newVideo.name : null,
      video: 'a crazy string.... FIGURE IT OUT',
    },
  ]);
};

export const getUserState = async (user) => {
  if (user) {
    const profile = await fetchUserProfile(user);
    return profile;
  } else return null;
};

export const fetchLatestVideos = async () => {
  const allVideos = await client
    .from('video_uploads')
    .select('*, profile:profiles(id, avatar_url)')
    .order('created_at')
    .limit(50);
  if (allVideos) {
    return allVideos.data;
  } else return null;
};

export const fetchLatestSongs = async () => {
  const allSongs = await client
    .from('songs')
    .select('*, profile:profiles(id, avatar_url)')
    .order('created_at')
    .limit(50);
  if (allSongs) {
    return allSongs.data;
  } else return null;
};

export const searchFeature = async (query) => {
  const searchResultsUser = await client
    .from('profiles')
    .select('*')
    .ilike('username', `%${query}%`);

  const searchResultsVideos = await client
    .from('video_uploads')
    .select(`*`)
    .or(`description.ilike.%${query}%,title.ilike.%${query}%`);

  const searchResultsSongs = await client
    .from('songs')
    .select(`*`)
    .or(`description.ilike.%${query}%,title.ilike.%${query}%`);

  const searchResults = searchResultsUser.data.concat(
    searchResultsVideos.data,
    searchResultsSongs.data
  );

  // console.log('SEARCHRESULTSTITLE', searchResultsSongs);

  if (searchResults) {
    return searchResults;
  } else return null;
};

const avatarBucket = async (user_id, media) => {
  const avatarChange = await client.storage
    .from('avatars')
    .upload(`${user_id}/${media.name}`, media, {
      cacheControl: '3600',
      upsert: false,
    });

  if (avatarChange.error?.statusCode === '409') return null;

  return avatarChange;
};

export const uploadProfileAvatar = async (user_id, media) => {
  const response = await client
    .from('profiles')
    .update({
      avatar_url: `https://nqbvdgzoxvmdlnjovyqu.supabase.in/storage/v1/object/public/avatars/${user_id}/${media.name}`,
    })
    .match({ user_id })
    .single();

  await avatarBucket(user_id, media);
  return response.data;
};

const videoBucket = async (user_id, media) => {
  const response = await client.storage.from('videos').upload(`${user_id}/${media.name}`, media, {
    cacheControl: '3600',
    upsert: false,
  });
  checkError(response);
};

export const uploadNewVideo = async (newVideo, media, user_id) => {
  const response = await client.from('video_uploads').insert(newVideo);

  await videoBucket(user_id, media);

  return response;
};

const songBucket = async (user_id, media) => {
  const response = await client.storage.from('songs').upload(`${user_id}/${media.name}`, media, {
    cacheControl: '3600',
    upsert: false,
  });
  checkError(response);
};

export const uploadNewSong = async (newSong, media, user_id) => {
  const response = await client.from('songs').insert(newSong);

  await songBucket(user_id, media);

  return response;
};

export const uploadCallOut = async (opponent, media, currentUser) => {
  const response = await client
    .from('battles')
    .insert([
      {
        challenger: currentUser?.user_id,
        challenger_username: currentUser?.username,
        opponent: opponent?.user_id,
        opponent_username: opponent?.username,
        call_out: `https://nqbvdgzoxvmdlnjovyqu.supabase.in/storage/v1/object/public/videos/${currentUser.user_id}/${media.name}`,
      },
    ])
    .match({ user_id: currentUser.user_id });

  await videoBucket(currentUser.user_id, media);

  return checkError(response);
};

export const fetchAllBattles = async () => {
  const response = await client.from('battles').select();
  if (response) {
    return response.data;
  } else return null;
};

export const fetchMyBattles = async (user_id) => {
  if (user_id) {
    const response = await client
      .from('battles')
      .select('*')
      // CHANGED THE || OPERATOR TO A COMMA. IF ERRORS START POPPING UP LOOK HERE
      .match({ opponent: user_id, challenger: user_id });
    return checkError(response);
  }
};

// Use or lose fetchMyChall.....
// This should be used with "MyBattles"
export const fetchMyChallenges = async (user_id) => {
  const response = await client.from('battles').select().match({ challenger: user_id });
  return checkError(response);
};

export const respondToCallOut = async (user_id, id, media) => {
  const res = await client
    .from('battles')
    .update({
      response: `https://nqbvdgzoxvmdlnjovyqu.supabase.in/storage/v1/object/public/videos/${user_id}/${media.name}`,
    })
    .match({ id: id });
  await videoBucket(user_id, media);
  return checkError(res);
};

// Use or lose declineCall...
export const declineCallOut = async (id) => {
  const response = await client.from('battles').delete().match({ id });
  return checkError(response);
};

export const submitBattleComment = async (battle, newComment, username, user_id) => {
  const response = await client
    .from('battle_comments')
    .insert({ comments: newComment, battle: battle.id, username, user_id });

  return checkError(response);
};

export const fetchBattleComments = async (id) => {
  const response = await client
    .from('battle_comments')
    .select()
    .match({ battle: id })
    .order('id', { ascending: false });

  if (response.status === 406) {
    return {};
  }

  return response;
};
export const editBattleComment = async (id, comment) => {
  const response = await client.from('battle_comments').update({ comments: comment }).match({ id });
  console.log('RESPONSE FROM EDIT CALL', response);
  if (response.status === 406) {
    return {};
  }

  return response;
};
export const deleteBattleComments = async (id) => {
  const response = await client.from('battle_comments').delete().match({ id });
  if (response.status === 406) {
    return {};
  }

  return response;
};

export const submitPublicComment = async (video_id, newComment, username, user_id) => {
  const response = await client
    .from('public_video_comments')
    .insert({ comments: newComment, video_id, username, user_id });

  return checkError(response);
};

export const fetchPublicComments = async (video_id) => {
  const response = await client.from('public_video_comments').select().match({ video_id });
  console.log('RESPONSE FROM PUBLIC VIDEO COMMENT FETCH', response);

  if (response.status === 406) {
    return {};
  }

  return response;
};

export const editPublicComment = async (id, comment) => {
  const response = await client
    .from('public_video_comments')
    .update({ comments: comment })
    .match({ id });
  console.log('RESPONSE FROM EDIT PUBLIC COMMENT', response);
  if (response.status === 406) {
    return {};
  }

  return response;
};

export const deletePublicComments = async (id) => {
  const response = await client.from('public_video_comments').delete().match({ id });
  if (response.status === 406) {
    return {};
  }

  return response;
};

export const fetchRating = async (battle, contender) => {
  let response = await client
    .from('ratings')
    .select(`${contender}_rating`)
    .match({ battle })
    .single();

  if (response.error) {
    return { [`${contender}_rating`]: {} };
  }

  return response.data;
};

export const fetchExistingRating = async (battle, participant) => {
  const response = await client
    .from('ratings')
    .select(`${participant}_rating`)
    .match({ battle })
    .single();
  return checkError(response);
};

export const updateRatings = async (id, updatedRating, contender) => {
  const response = await client
    .from('ratings')
    .upsert({ [`${contender}_rating`]: updatedRating, battle: id }, { onConflict: 'battle' })
    .match({ battle: id })
    .single();

  return checkError(response);
};

export const updateViews = async (video_id) => {
  const viewCount = await client.from('video_uploads').select('views').match({ video_id }).single();
  const newCount = viewCount.data.views + 1;
  const response = await client
    .from('video_uploads')
    .update({ views: newCount })
    .eq('video_id', video_id)
    .select();
  return response;
};

export const updateLikes = async (video_id) => {
  const viewCount = await client.from('video_uploads').select('likes').match({ video_id }).single();
  const newCount = viewCount.data.likes + 1;
  const response = await client
    .from('video_uploads')
    .update({ likes: newCount })
    .eq('video_id', video_id)
    .select();
  // console.log('RESPONSE FROM VIEW UPDATE', response);
  return response;
};
export const chatSubscription = async (chatUpdater) => {
  const response = await client
    .from('chat_messages')
    .on('*', (payload) => {
      console.log('ACTION DETECTED', payload);
      chatUpdater(payload);
    })
    .subscribe();
  console.log('RESPONSE FROM VIEW UPDATE', response);
  return response;
};

export const removeChatSubscription = async (sub) => {
  const response = await client.removeSubscription(sub);
  // console.log('RESPONSE FROM REMOVE', response);
  return response;
};

export const fetchChats = async (username) => {
  const response = await client
    .from('chats')
    .select('*, messages:chat_messages(*)')

    .or(`user_1.eq.${username},user_2.eq.${username}`)
    .order('created_at', { ascending: false });
  // console.log('RESPONSE IN CHAT MESSAGES FETCH CALL', response);

  return response;
};

export const fetchChatMessages = async (chat_id) => {
  const response = await client.from('chat_messages').select('*').match({ chat_id });
  // console.log('RESPONSE FROM FETCH CHAT CALL', response);
  return response;
};

export const uploadNewMessage = async (newMessage) => {
  const response = await client.from('chat_messages').insert([newMessage]);
  return response;
};

export const initiateChat = async (chatInfo) => {
  const response = await client.from('chats').insert(chatInfo).single();
  return response.data;
};

export const editChatMessage = async (message) => {
  const response = await client.from('chat_messages').update({ message }).match({ id: message.id });
  console.log('RESPONSE FROM EDIT CHAT MESSAGE', response);
  return response;
};

export const deleteChatMessage = async (id) => {
  const response = await client.from('chat_messages').delete().match({ id }).single();
  console.log('RESPONSE FROM DELETE CHAT MESSAGE', response);
  return response;
};

export const getUserEvents = async (user_id) => {
  const response = await client.from('events').select('*').match({ user_id });
  console.log('RESPONSE FROM USER EVENT FETCH CALL', response);
  return checkError(response);
};

export const getEventById = async (id) => {
  if (id) {
    const response = await client
      .from('events')
      .select(`*, attending_count:attending_event(count)`)
      .match({ id })
      .single();
    console.log('EVENT DETAILS IN UTILS', response);
    return response;
  }
};

export const addEvent = async ({ eventName, date, time, address, user_id }) => {
  const response = await client
    .from('events')
    .insert({ event_name: eventName, date, time, address, user_id })
    .select('*')
    .single();

  const attend = await attendEvent({ event_id: response.data.id, user_id });

  if (response.status === 201 && attend.status === 201) {
    return checkError(response);
  }
};

export const deleteEventRow = async ({ id }) => {
  const response = await client.from('events').delete().match({ id }).single();
  return checkError(response);
};

export const attendEvent = async ({ event_id, user_id }) => {
  const response = await client.from('attending_event').insert({ event_id, user_id }).select('*');
  return response;
};

export const cancelAttendEvent = async ({ event_id, user_id }) => {
  const response = await client
    .from('attending_event')
    .delete()
    .match({ event_id, user_id })
    .single('*');
  return response;
};

export const followUser = async (followObj) => {
  const response = await client.from('follows').insert(followObj);
  console.log('RESPONSE FROM FOLLOW', response);
  return response;
};

// BELOW FUNCTION FETCHES A LIST OF USERS WHO FOLLOW YOU
export const fetchFollowers = async (user_id) => {
  if (user_id) {
    const response = await client.from('follows').select('*').match({ followed: user_id });
    return response;
  }
};

// BELOW FUNCTION FETCHES A LIST OF USERS WHO YOU FOLLOW
export const fetchFollows = async (user_id) => {
  if (user_id) {
    const response = await client.from('follows').select('*').match({ follower: user_id });
    return response;
  }
};

export const fetchFeed = async (feedArray) => {
  if (feedArray?.length > 0) {
    const videos = await client
      .from('video_uploads')
      .select('*')
      .in('username', feedArray)
      .order('created_at');

    const songs = await client
      .from('songs')
      .select('*')
      .in('username', feedArray)
      .order('created_at');

    const dataArray = videos.data.concat(songs.data);

    // console.log(' dataArray IN FETCH FEED CALL', dataArray);
    return dataArray;
  }
};

// BELOW FUNCTION IS AN ALTERNATIVE WAY TO FETCH DATA FOR FEED COMPONENT
// REQUIRES MUNGING ON FRONT END
//
export const fetchFeedALTERNATIVE = async (feedArray) => {
  if (feedArray?.length > 0) {
    // return null
    const profile = await client
      .from('profiles')
      .select('id, videos:video_uploads(*), songs:songs(*)')
      .in('username', feedArray)
      .order('created_at', { foreignTable: 'video_uploads', ascending: false })
      .order('created_at', { foreignTable: 'songs', ascending: false });
    // console.log('ARRAY OF PROFILES IN FETCH FEED CALL!!!', profile);
    return profile;
  }
};

export const fetchArtistWalletBalance = async (user_id) => {
  const res = await client.from('wallet').select('balance').match({ user_id }).single();
  console.log('RESPONSE FROM ARTIST WALLET BALANCE REQUEST', res);
  return res.data;
};

export const donateToArtist = async (amount, user_id) => {
  const res = await client
    .from('wallet')
    .update({ balance: amount })
    .match({ user_id })
    .select()
    .single();
  console.log('RESPONSE FROM ARTIST DONATION', res, user_id);
  return res.data;
};

export const updateWalletAfterDonate = async (amount, user_id) => {
  const res = await client
    .from('wallet')
    .update({ balance: amount })
    .match({ user_id })
    .select()
    .single();
  console.log('RESPONSE FROM UPDATE WALLET AFTER DONATE', res, user_id);
  return res.data;
};

export const createTransaction = async ({ sender_id, receiver_id, tranxHash, amount }) => {
  // console.log('TRANX OBJECT CREATE TRANSACTION', tranx);
  const res = await client
    .from('transactions')
    .insert({
      sender_id,
      receiver_id,
      signature: tranxHash,
      amount,
    })
    .select('*')
    .single();
  console.log('RESPONSE FROM CREATE TRANSACTION', res);
  return res;
};

export const transactionCount = async () => {
  const res = await client.from('transactions').select('*', { count: 'exact' });
  // console.log('RESPONSE FROM TRANSACTION COUNT', res);
  return res;
};

export const clearTransactions = async () => {
  const res = await client.from('transactions').delete().neq('id', '0');
  console.log('RESPONSE FROM DELETE ALL ROWS IN TRANSACTION', res);
};

export const fetchLedger = async () => {
  const res = await client.from('ledger').select('*').order('id', { ascending: true });
  // console.log('FETCH LEDGER LIST', res);
  return res;
};

export const addBlockToLedger = async (block) => {
  const res = await client.from('ledger').insert(block).select('*').single();
  console.log('RESPONSE FROM ADD BLOCK TO LEDGER', res);
  if (res.status === 201) {
    clearTransactions();
  }
  return res;
};

// export const ledgerSubscription = async (ledgerUpdater) => {
//   const response = await client
//     .from('ledger')
//     .on('*', (payload) => {
//       console.log('LEDGER ACTION DETECTED', payload);
//       ledgerUpdater(payload);
//     })
//     .subscribe();

//   console.log('RESPONSE FROM LEDGER UPDATE', response);
//   return response;
// };

const productImageUpload = async (media) => {
  const response = await client.storage.from('product_images').upload(`${media.name}`, media, {
    cacheControl: '3600',
    upsert: true,
  });
  return response;
};

export const insertProduct = async (data, media) => {
  const response = await client.from('merchandise').insert(data);

  const imageUpload = await productImageUpload(media);

  console.log('RESPONSE FROM PRODUCT INSERT', response);
  console.log('IMAGE BUCKET RESPONSE FROM INSERT', imageUpload);
  return response.data;
};

export const getArtistProducts = async (user_id) => {
  const response = await client
    .from('merchandise')
    .select('*')
    .match({ user_id })
    .order('created_at', { ascending: false });
  console.log('RESPONSE FROM GET ARTIST PRODUCTS', response);
  return response.data;
};

export const getProductById = async (id) => {
  const response = await client.from('merchandise').select('*').match({ id }).single();
  return response.data;
};

export const deleteProductById = async (id) => {
  const response = await client.from('merchandise').delete().match({ id });
  const imagePath = parseImagePath(response.data[0].image);
  const deleteProductImage = await client.storage.from('product_images').remove([`/${imagePath}`]);
  console.log('RESPONSE FROM DELETE PRODUCT', response);
  console.log('RESPONSE FROM DELETE PRODUCT IMAGE', deleteProductImage);
  return response;
};
