import { useUser } from '../hooks/useUser';
import { useChats } from '../hooks/useChats';

export const ChatList = () => {
  const { user } = useUser();
  const { openChats, setChatMessages } = useChats();

  const handleClick = (i) => {
    // console.log('OPEN CHATS LIST ITEM', openChats[i]);
    setChatMessages(openChats[i]);
  };

  return (
    <div className="dm-container">
      {openChats?.length > 0 &&
        openChats.map((chat, i) => {
          return (
            <div key={i}>
              <p
                onClick={() => {
                  handleClick(i);
                }}
              >
                {chat.user_1 === user.username ? chat.user_2 : chat.user_1}
              </p>
            </div>
          );
        })}
    </div>
  );
};
