import '../css/Comments.css';
import Comment from '../features/Comment';
import React, { useEffect, useState } from 'react';
import UploadPublicComment from '../forms/UploadPublicComment';
import useRenderCounter from '../services/checkRender';
import { fetchPublicComments } from '../services/fetch-utils';

export default function PublicComments({ video_id }) {
  useRenderCounter('PUBLIC COMMENTSS');
  const [comments, setPublicComments] = useState('');

  useEffect(() => {
    fetchPublicComments(video_id).then((commentArray) =>
      setPublicComments(commentArray?.data.reverse())
    );
  }, [video_id]);

  return (
    <div className="comment-container">
      <h4 className="comment-header">Comments Section</h4>
      <div className="comments-section">
        {comments ? (
          comments.map((comment, i) => {
            {
              /* console.log('INDIVIDUAL COMMENT', comment); */
            }
            return (
              <div key={`battle-${i}`}>
                <Comment comment={comment} setComments={setPublicComments} location={'public'} />
                <br></br>
              </div>
            );
          })
        ) : (
          <p>Comments Loading</p>
        )}
      </div>
      <UploadPublicComment video_id={video_id} setComments={setPublicComments} />
    </div>
  );
}
