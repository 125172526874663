/* eslint-disable indent */
import { useState } from 'react';
import { useUser } from '../hooks/useUser';
import { useChats } from '../hooks/useChats';
import { initiateChat, uploadNewMessage } from '../services/fetch-utils';

import { SingleMessage } from '../views/SingleMessage';
import useRenderCounter from '../services/checkRender';

export const ChatMessages = () => {
  useRenderCounter('CHATS');

  const { user } = useUser();
  const { chatMessages, setChatMessages } = useChats();
  const [newChatMessage, setNewChatMessage] = useState('');

  const startChat = async () => {
    const chatInfo = {
      user_1: user.username,
      user_2: chatMessages.user_2,
    };
    const newChat = await initiateChat(chatInfo);
    const newChatObj = { ...newChat, messages: [] };

    setChatMessages(newChatObj);

    console.log('NEW CHAT OBJ FROM ADD NEW MESSAGE1', newChatObj);

    const newUpload = await uploadNewMessage({
      chat_id: newChatObj.chat_id,
      sender: user.username,
      message: newChatMessage,
    });
    setNewChatMessage('');
    return newUpload;
  };

  const handleUploadNewMessage = async (e) => {
    e.preventDefault();
    if (!chatMessages?.chat_id) {
      const newChat = await startChat();
      // THIS RETURN IS ESSENTIALLY ONLY NEEDED TO EXIT THE FUNCTION
      // A TRY CATCH WOULD BE BETTER FOR ERROR HANDLING AND WHAT NOT
      // CONSIDER THE SCALE. THIS IS GONNA BE A HIGH VOLUME FUNCTION
      return console.log('NEW UPLOAD RESPONSE', newChat);
    }

    const message = {
      chat_id: chatMessages.chat_id,
      sender: user.username,
      message: newChatMessage,
    };

    await uploadNewMessage(message);
    setNewChatMessage('');
  };
  const handleMessageChange = (e) => {
    setNewChatMessage(e.target.value);
  };

  let chatMessagesContainer = <h1>LOADING MESSAGES...</h1>;
  if (chatMessages?.messages?.length > 0) {
    const newArray = [...chatMessages.messages.reverse()];
    chatMessagesContainer = newArray.map((message, i) => {
      return <SingleMessage message={message} key={i} />;
    });
  }

  return (
    <div className="chat-messages-container">
      {chatMessages?.messages?.length > 0 && chatMessages.user_1 === user?.username
        ? chatMessages.user_2
        : chatMessages.user_1}
      {chatMessagesContainer}

      <form className="dm-input-form" onSubmit={handleUploadNewMessage}>
        <input
          onChange={handleMessageChange}
          placeholder="Say something nice"
          value={newChatMessage}
        />
        <button>Send</button>
      </form>
    </div>
  );
};
