import { useUser } from '../hooks/useUser';
import { deleteChatMessage, fetchChatMessages } from '../services/fetch-utils';
import { useChats } from '../hooks/useChats';
import { toast } from 'react-hot-toast';

export const SingleMessage = ({ message }) => {
  const { user } = useUser();
  const { chatUpdater } = useChats();

  const mine = message?.sender === user?.username ? true : false;

  const handleDelete = async (id) => {
    const deleteResponse = await deleteChatMessage(id);

    // console.log(deleteResponse);
    // const updateChatList = fetchChatMessages(deleteResponse?.data.chat_id).then((res) =>
    //   chatUpdater(res.data)
    // );
    // toast.promise(updateChatList, {
    //   loading: 'One moment while we remove this message',
    //   success: 'Message successfully deleted',
    //   error: 'Ooops, something went wrong. Please try again',
    // });
  };

  return (
    <span
      className="single-message-span"
      style={{
        justifyContent: mine ? 'right' : 'left',
      }}
    >
      <p className="single-message-p" style={{ backgroundColor: mine ? 'blue' : 'grey' }}>
        {message.message}
      </p>
      {mine ? (
        <span className="chat-message-controls">
          <img
            className="chat-message-control-icons"
            src="/edit-icon.png"
            style={{ width: '15px', marginLeft: '3px' }}
            // onClick={() => handleCommentEdit()}
          ></img>
          <img
            className="chat-message-control-icons"
            src="/delete-icon.png"
            style={{ width: '15px', marginLeft: '3px' }}
            onClick={() => handleDelete(message.id)}
          ></img>
        </span>
      ) : null}
    </span>
  );
};
