import { useLocation, useNavigate } from 'react-router-dom';
import { useCart } from '../hooks/useCart';
// import '../css/ProductView.css';
import { deleteProductById, fetchProfileById } from '../services/fetch-utils';
import { useUser } from '../hooks/useUser';

export const ProductView = ({ product }) => {
  //   console.log('PRODUCT IN PRODUCT VIEW', product);
  const { user, updateMerch } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const { addToCart } = useCart();
  //   const publicProfilePage = new RegExp('^/profile/[0-9]*$');
  //   const isPublicProfilePage = publicProfilePage.test(location.pathname);
  const detailPage = new RegExp('^/detail/[0-9]*$');
  const isDetailPage = detailPage.test(location.pathname);
  const myProducts = user.user_id === product.user_id ? true : false;

  const handleClick = () => {
    !isDetailPage && navigate(`/detail/${product.id}`);
  };

  const handleAddCartItem = () => {
    addToCart(product);
  };

  const handleArtistItemDelete = () => {
    deleteProductById(product.id).then(() => updateMerch());
    // deleteProductById(product.id).then((res) =>
    //   console.log('Need to update merch arr in user obj', res)
    // );
  };

  return (
    <div className="product-view-card-container">
      <img
        onClick={() => handleClick()}
        className="product-view-card-image product-view-p"
        src={product.image}
      ></img>
      <p className="product-view-card-name product-view-p">{product.name}</p>
      <p className="product-view-card-description product-view-p">{product.description}</p>
      <p className="product-view-card-price product-view-p">Price : ${product.price}</p>
      {myProducts && (
        <span>
          <button onClick={() => handleArtistItemDelete()}>Delete</button>
        </span>
      )}
      {!myProducts && <button onClick={(e) => handleAddCartItem()}>Add to Cart</button>}
    </div>
  );
};
