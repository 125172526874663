import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../css/SupportArtistForm.css';
import {
  fetchArtistWalletBalance,
  donateToArtist,
  updateWalletAfterDonate,
} from '../services/fetch-utils';
import { toast } from 'react-hot-toast';
import { useLedger } from '../hooks/useLedger';

import { useUser } from '../hooks/useUser';

export const SupportArtistForm = ({ profile }) => {
  console.log('PROFILE PASSED INTO SUPPORT FORM', profile);
  const [donationAmount, setDonationAmount] = useState(1);
  const [artistWalletAmount, setArtistWalletAmount] = useState(0);

  const location = useLocation();
  const { user, updateWallet } = useUser();
  const { createNewTransaction } = useLedger();

  useEffect(() => {
    const artistWalletBalance = async () => {
      const amount = await fetchArtistWalletBalance(profile.user_id);
      setArtistWalletAmount(amount);
    };
    artistWalletBalance();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (user.wallet?.balance >= donationAmount) {
      const updatedArtistBalance = Number(donationAmount) + Number(artistWalletAmount.balance);
      const updateUserWallet = Number(user.wallet?.balance) - Number(donationAmount);
      try {
        const artistDonation = donateToArtist(updatedArtistBalance, profile.user_id)
          .catch((error) => error.message)
          .then(() => updateWalletAfterDonate(updateUserWallet, user?.user_id))
          .then((res) => {
            updateWallet(res?.balance);
          })
          .then(() => {
            createNewTransaction(user?.user_id, profile.user_id, donationAmount);
          })
          .then(() => setDonationAmount(1));

        toast.promise(artistDonation, {
          loading: 'Just a moment while we process your donation',
          success: `Donation successfully sent to ${profile.username}`,
          error: 'Oops! Something went wrong. Please try again.',
        });
      } catch (error) {
        toast.error('Something went wrong. Please try again');
      }
    } else toast.error('Not Enough Funds');
  };

  return (
    <div className="support-artist-form-container">
      <h3>{`Help ${profile.username} Express Themselves`}</h3>
      <form onSubmit={handleSubmit} id="create-event-form">
        <label
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {`How Much Would You like To Donate to ${profile.username}?`}
          <input
            id="amount"
            type="number"
            value={donationAmount}
            min={1}
            onChange={(e) => {
              setDonationAmount(e.target.value);
            }}
          ></input>
        </label>

        <button>{`Support ${profile.username} with a $${donationAmount} donation`}</button>
      </form>
    </div>
  );
};
