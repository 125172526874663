import '../css/Auth.css';
import React from 'react';
import { useState } from 'react';
import { SignUpForm } from '../forms/SignUpForm';
import { SignInForm } from '../forms/SignInForm';
import useRenderCounter from '../services/checkRender';

export default function Auth() {
  const [check, setCheck] = useState(true);

  const operation = check ? 'Click here to Sign Up' : 'Click here to Sign In';

  useRenderCounter('AUTH');

  return (
    <div className="auth-container">
      <div>{check ? <SignInForm></SignInForm> : <SignUpForm></SignUpForm>}</div>
      <button onClick={() => (check ? setCheck(false) : setCheck(true))}>{operation}</button>
    </div>
  );
}
