const openMediaDevices = async (constraints) => {
  return await navigator.mediaDevices.getUserMedia(constraints);
};

export const videoFeed = async () => {
  let videoStream;
  let stream;
  try {
    stream = await openMediaDevices({ video: true, audio: true });
    videoStream = document.createElement('video');
    videoStream.autoplay = true;
    videoStream.playsInline = true;
    videoStream.muted = true;
    videoStream.srcObject = stream;
    videoStream.id = 'videoStream';
    console.log('STREAM OBJECT', stream);
    console.log('VIDEO_STREAM OBJECT', videoStream);
    console.log('ytpeof STREAM OBJECT', typeof stream);
    console.log('typeof VIDEO_STREAM OBJECT', typeof videoStream);
    const liveFeed = document.querySelector('div#Live');
    liveFeed.append(videoStream);
  } catch (error) {
    console.error('Error accessing media devices.', error);
  }
};
