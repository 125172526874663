import React, { useState } from 'react';
import useRenderCounter from '../services/checkRender';
import { SearchResults } from '../components/SearchResults';
import { searchFeature } from '../services/fetch-utils';

export default function Search() {
  const styles = import('../css/Search.css');
  // useRenderCounter('SEARCH');

  const [displayResults, setDisplayResults] = useState(null);
  const [query, setQuery] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    searchFeature(query).then((results) => setDisplayResults(results));
  };

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <div style={{ styles }} className="filter-func">
      <form onSubmit={handleSubmit}>
        <label>
          Search
          <input onChange={handleChange}></input>
        </label>
        <button>Search</button>
        {displayResults ? (
          <button onClick={() => setDisplayResults(null)}>Clear Results</button>
        ) : null}
      </form>
      {displayResults ? <SearchResults results={displayResults} /> : null}
    </div>
  );
}
