/* eslint-disable indent */
import { updateViews } from '../services/fetch-utils';
import { useNavigate, useLocation } from 'react-router-dom';
import { VideoInfoCard } from '../components/createCard';

export const VideoDisplay = ({ video }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const mediaPage = new RegExp('^/media/[0-9]*$');
  const showControls = mediaPage.test(location.pathname);

  let userData;
  showControls ? (userData = location.state.video) : (userData = video);

  return (
    <div
      style={
        showControls
          ? {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              position: 'relative',
            }
          : null
      }
    >
      <video
        style={{ width: showControls ? '70%' : '100%', borderRadius: '10px' }}
        className="user-videos"
        controls={showControls}
        // !!THIS ONCLICK WILL NAVIGATE TO DETAIL PAGE *OR* PLAY VIDEO!!
        //      !!THE CONDITION CHECKS CURRENT PAGE LOCATION!!
        onClick={
          showControls
            ? null
            : () => {
                updateViews(userData.video_id);
                navigate(
                  showControls ? `./media/${userData.video_id}` : `/media/${userData.video_id}`,
                  { state: { video } }
                );
              }
        }
      >
        <source src={userData?.video} type="video/mp4" />
      </video>
      <VideoInfoCard videoInfo={userData} />
    </div>
  );
};
