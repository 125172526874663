import '../css/Sidebar.css';
import { useState } from 'react';
import UploadSongs from '../forms/UploadSongs';
import UploadAvatar from '../forms/UploadAvatar';
import UploadVideos from '../forms/UploadVideos';
import UpdateUsername from '../forms/UpdateUsername';

export const Sidebar = () => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  return (
    <span className={sidebarToggle ? 'sidebar' : 'sidebar closed'}>
      <img
        className="sidebar-icon"
        src={sidebarToggle ? '/close-icon.png' : '/hamburger-icon.png'}
        style={{ width: '1.5rem', height: '1.5rem', margin: '3px' }}
        onClick={() => (sidebarToggle ? setSidebarToggle(false) : setSidebarToggle(true))}
      ></img>
      <span className={sidebarToggle ? 'sidebar-content' : 'sidebar-content close-content'}>
        <hr></hr>
        <img
          className="edit-icon"
          src={'/edit-icon.png'}
          style={{ width: '2rem', height: '2rem' }}
        ></img>
        <UpdateUsername />
      </span>

      <span className={sidebarToggle ? 'sidebar-content' : 'sidebar-content close-content'}>
        <img
          className="profile-icon"
          src={'/profile-icon.png'}
          style={{ width: '2rem', height: '2rem' }}
        ></img>
        <UploadAvatar />
      </span>

      <span className={sidebarToggle ? 'sidebar-content' : 'sidebar-content close-content'}>
        <img
          className="video-icon"
          src={'/video-icon.png'}
          style={{ width: '2rem', height: '2rem' }}
        ></img>
        <UploadVideos />
      </span>

      <span className={sidebarToggle ? 'sidebar-content' : 'sidebar-content close-content'}>
        <img
          className="audio-icon"
          src={'/audio-icon.png'}
          style={{ width: '2rem', height: '2rem' }}
        ></img>
        <UploadSongs />
      </span>
    </span>
  );
};
