import { useState } from 'react';
import { signInUser } from '../services/fetch-utils';

export const SignInForm = () => {
  const [emailSignIn, setEmailSignIn] = useState('');
  const [passwordSignIn, setPasswordSignIn] = useState('');

  const handleSignInEmailChange = (e) => {
    setEmailSignIn(e.target.value);
  };
  const handleSignInPasswordChange = (e) => {
    setPasswordSignIn(e.target.value);
  };
  const handleSignInSubmit = async (e) => {
    e.preventDefault();
    const user = await signInUser(emailSignIn, passwordSignIn);
    console.log('USER AT LOGIN', user);
    if (user) {
      location.replace('/');
    }
  };
  return (
    <div className="auth-form-container">
      <p className="auth-form-title">Sign In</p>
      <form className="auth-form" onSubmit={handleSignInSubmit}>
        <label className="auth-form-input-label">
          Email
          <input
            className="auth-form-input"
            value={emailSignIn}
            onChange={handleSignInEmailChange}
          ></input>
        </label>
        <label className="auth-form-input-label">
          Password
          <input
            className="auth-form-input"
            value={passwordSignIn}
            onChange={handleSignInPasswordChange}
            type={'password'}
          ></input>
        </label>
        <button className="auth-form-submit-button">Submit</button>
      </form>
    </div>
  );
};
