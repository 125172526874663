import {
  fetchLedger,
  transactionCount,
  addBlockToLedger,
  createTransaction,
} from '../services/fetch-utils';
import sha256 from 'crypto-js/sha256';
import { tranxCrypto } from '../ledger/transactionCrypto';
import { createContext, useState, useEffect } from 'react';

export const LedgerContext = createContext(null);

export const LedgerProvider = ({ children }) => {
  const [ledger, setLedger] = useState([]);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    fetchLedger().then((ledger) => setLedger(ledger.data[ledger.data?.length - 1].current_hash));
  }, []);

  useEffect(() => {
    transactionCount().then((res) => {
      setTransactions([...res.data]);
    });
  }, []);

  //   useEffect(() => {
  //     console.log('CURRENT HASH OF PREVIOUS BLOCK', ledger);
  //   }, [ledger]);

  //   useEffect(() => {
  //     console.log('TRANSACTIONS HAS BEEN UPDATED', transactions);
  //   }, [transactions]);

  const createNewTransaction = async (sender_id, receiver_id, amount) => {
    const tranxData = {
      sender_id,
      receiver_id,
      amount,
    };

    const tranxHash = tranxCrypto({ ...tranxData });

    createTransaction({ ...tranxData, tranxHash }).then((res) => {
      setTransactions(transactions.length > 0 ? [...transactions, res.data] : [res.data]);
    });
  };

  useEffect(() => {
    // functions to create new hash given incremented nonce until hash begins with how ever many 0's the difficulty is
    // 3 difficulty means hash must start with 3 0's
    const createHash = (nonce) => {
      const previousHash = ledger;
      const timeNow = Date.now();

      const newHash = sha256(
        previousHash + timeNow + JSON.stringify(transactions) + nonce
      ).toString();

      return newHash;
    };

    const createBlock = (difficulty) => {
      var nonce = 0;
      var hash = '';
      createHash(nonce);
      while (hash.substring(0, difficulty) !== Array(difficulty + 1).join('0')) {
        hash = createHash(nonce);
        nonce++;
      }
      const block = {
        transactions,
        previous_hash: ledger,
        current_hash: hash,
      };
      //   console.log('WHAT IS THE BLOCK THATS BEING SENT UP?', block, transactions);
      return block;
    };

    if (transactions.length >= 10) {
      const block = createBlock(3);
      setTransactions([]);
      //   console.log('DOES THE CREATE A BLOCK RESOLVE??', block);
      addBlockToLedger(block)
        .then((res) => setLedger(res.data.current_hash))
        .then(() =>
          transactionCount().then((res) => {
            setTransactions(res.data);
          })
        );
    }
    // else console.log('WHY NO FIRE?', transactions);
  }, [transactions, ledger]);

  return (
    <LedgerContext.Provider value={{ ledger, setLedger, createNewTransaction }}>
      {children}
    </LedgerContext.Provider>
  );
};
