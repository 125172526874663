import toast from 'react-hot-toast';
import React, { useState } from 'react';
import { useUser } from '../hooks/useUser';
import useRenderCounter from '../services/checkRender';
import { uploadProfileAvatar } from '../services/fetch-utils';

export default function UploadAvatar() {
  // useRenderCounter('UPLOADAVATAR');
  const { user, setUser } = useUser();

  const [media, setMedia] = useState('');

  const handleMediaChange = (e) => {
    setMedia(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const upload = uploadProfileAvatar(user.user_id, media)
      .then((updatedUser) => setUser(updatedUser))
      .then(() => {
        e.target[0].value = null;
        setMedia(null);
      });

    toast.promise(upload, {
      loading: 'Just a moment while we update your Avatar',
      success: 'Avatar successfully updated',
      error: 'Oops! Something went wrong. Please try again',
    });
  };
  return (
    <form onSubmit={handleSubmit}>
      <label>
        Change Avatar
        <input type="file" onChange={handleMediaChange}></input>
      </label>
      <button>Submit</button>
    </form>
  );
}
