/* eslint-disable indent */
import { useNavigate, useLocation } from 'react-router-dom';
import '../css/SongDisplay.css';

export const SongDisplay = ({ song }) => {
  // const navigate = useNavigate();
  const location = useLocation();
  const mediaPage = new RegExp('^/media/[0-9]*$');
  const showControls = mediaPage.test(location.pathname);

  let songData;
  showControls ? (songData = location.state.user) : (songData = song);

  return (
    <div className="auido-track-container">
      {songData.image ? <img src={songData.image} /> : null}
      <audio className="audio-tag-proper" id={`audio-track-${songData.song_id}`} controls>
        <source src={songData.media} type="audio/mp3" />
      </audio>
      <span className="audio-track-details">
        <p className="audio-track-details-info">{songData.username}</p>
        <p className="audio-track-details-info">Title: {songData.title}</p>
        <p className="audio-track-details-info">Description: {songData.description}</p>
      </span>
    </div>
  );
};
