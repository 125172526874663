import PublicComments from './PublicComments';
import { useLocation } from 'react-router-dom';
import { VideoDisplay } from '../views/VideoDisplay';
import '../css/VideoMedia.css';

export const VideoMedia = () => {
  const location = useLocation();

  return (
    <div className="video-media-container">
      <VideoDisplay />
      <PublicComments video_id={location.state.video.video_id} />
    </div>
  );
};
