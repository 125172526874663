import '../css/Chats.css';
import { ChatList } from '../features/ChatList';
import { ChatMessages } from '../features/ChatMessages';

export const Chats = () => {
  // const cleanUp = async () => {
  //   const res = await removeChatSubscription(chatSub);
  //   if (res.data.openSubscriptions === 0) setChatSub(null);
  //   setChatMessages([]);
  // };

  // const subscribe = async () => {
  //   const subscription = await chatSubscription(chatUpdater);
  //   setChatSub(subscription);
  // };

  // const handleSubscribe = () => {
  //   chatSub ? cleanUp() : subscribe();
  // };

  return (
    <div className="dm-tab">
      <ChatList />
      <ChatMessages />
    </div>
  );
};
