import { useState } from 'react';
import { useUser } from '../hooks/useUser';
import toast from 'react-hot-toast';
import useRenderCounter from '../services/checkRender';
import { uploadNewVideo, getUserState } from '../services/fetch-utils';

export default function UploadVideos() {
  // useRenderCounter('UPLOADVIDEOS');
  const { user, setUser } = useUser();

  const [media, setMedia] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const handleMediaChange = (e) => {
    setMedia(e.target.files[0]);
  };
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const resetInputs = (e) => {
    e.target[0].value = null;
    e.target[1].value = '';
    e.target[2].value = '';
    setTitle('');
    setMedia(null);
    setDescription('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // NOTE: USERS_ID BELOW IS NOT THE SAME AS USER_ID. IT IS THE PROFILE
    // ID. THIS WAS DONE INTENTIONALLY TO MATCH AN INNER JOIN.
    const newVideo = {
      title,
      description,
      id: user.id,
      username: user.username,
      video: `https://nqbvdgzoxvmdlnjovyqu.supabase.in/storage/v1/object/public/videos/${user.user_id}/${media.name}`,
    };

    const upload = uploadNewVideo(newVideo, media, user.user_id)
      .catch((error) => error.message)
      .then(() => getUserState(user.user_id))
      .then((res) => setUser(res))
      .then(() => resetInputs(e));

    toast.promise(upload, {
      loading: 'Just a moment while we upload your Video',
      success: 'Video successfully uploaded',
      error: 'Oops! Something went wrong. Please try again',
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      Upload New Videos
      <label>
        <input type="file" onChange={handleMediaChange}></input>
      </label>
      <label className="video-upload-inputs title">
        Title
        <input type="text" onChange={handleTitleChange}></input>
      </label>
      <label className="video-upload-inputs description">
        Description
        <input type="text" onChange={handleDescriptionChange}></input>
      </label>
      <button>Submit</button>
    </form>
  );
}
