import { useState, useEffect } from 'react';
import { fetchLatestVideos } from '../services/fetch-utils';
import { VideoDisplay } from '../views/VideoDisplay';
import '../css/LatestVideos.css';

export default function LatestVideos() {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    try {
      fetchLatestVideos().then((videos) => setVideos(videos.reverse()));
    } catch (error) {
      console.error('No Media yet');
      setVideos([]);
    }
  }, []);

  return (
    <div className="latest-videos">
      {videos.map((video, i) => {
        return (
          <div key={video.username + i + video.video_id} className="latest-video-list">
            <VideoDisplay video={video} />
          </div>
        );
      })}
    </div>
  );
}
