import { createContext, useState, useEffect } from 'react';
import {
  authListener,
  fetchUser,
  fetchUserProfile,
  getArtistProducts,
} from '../services/fetch-utils';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // CALL SUPABASE AUTH EVENT LISTENER
  authListener();

  // Try to fetch current user
  let currUser;
  try {
    currUser = fetchUser();
  } catch (error) {
    console.log('ERROR FROM NO USER', error);
  }

  const [user, setUser] = useState({});

  const updateWallet = (updatedBalance) => {
    console.log('THIS SHOULD BE A NUMBER IN USER CONTEXT', updatedBalance);
    setUser({ ...user, wallet: { balance: updatedBalance } });
  };

  const updateMerch = () => {
    getArtistProducts(user.user_id).then((res) => setUser({ ...user, merch: res.data }));
  };

  useEffect(() => {
    try {
      fetchUserProfile(currUser.id).then((profile) => setUser(profile));
    } catch (error) {
      console.error('No User signed in');
      setUser(null);
    }
  }, []);

  useEffect(() => {
    console.log('UPDATED USER IN USER CONTEXT', user);
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser, updateWallet, updateMerch }}>
      {children}
    </UserContext.Provider>
  );
};
